import * as React from 'react'

import SEO from '../components/Commons/SEO'


const IndexPage = ():JSX.Element => {

    return (
        <>
            <SEO title="Clinica" />
            <h1>HOME</h1>

        </>
    )
}

export default IndexPage
